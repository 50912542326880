"use client";

import Cookies from "js-cookie";

import { VISIT_COOKIE_NAME } from "@shared/constants";

import { logError } from "@shared/functions/log";
import { throwUndefinedVariable } from "@shared/functions/throwError";

import type Visit from "@packages/types/visit";

export default function getVisitCookie(): Visit.Cookie | undefined {
  if (!VISIT_COOKIE_NAME) {
    throwUndefinedVariable("VISIT_COOKIE_NAME");
  }

  let cookie = Cookies.get(VISIT_COOKIE_NAME);

  if (cookie) {
    try {
      return JSON.parse(cookie);
    } catch (e) {
      logError({
        message: "getVisitCookie was unable to parse cookie",
        event: {
          name: "getVisitCookie",
          meta: {
            cookie,
          },
        },
      });
    }
  }

  return;
}
