"use client";


import { useFormikContext } from "formik";

import sleep from "@shared/functions/sleep";

import postEvent from "@server/front-end-api/postEvent";
import validatePhoneNumber from "@server/twilio/validatePhoneNumber";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Phone from "@client/components/formik/phone-number";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

const INPUT_ID_AND_LABEL_HTMLFOR = "phone-number";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function PhoneNumber() {
  const visitCookie = getVisitCookie();

  const { submitCount, setStatus } = useFormikContext();

  async function phoneNumberValidation(phoneNumber: string) {
    // The error message to display to the user
    let errorMessage;

    // Reset the lead form status via Formik
    setStatus("");

    /**
     * Skip Twilio validation if:
     *  - The form has been submitted more than once.
     *  - Twilio is not enabled.
     */
    if (submitCount > 0 || !visitCookie?.twilioEnabled) {
      return ""; // No validation error
    }

    // Validate the phone number using Twilio
    const isValidPhoneNumber = await validatePhoneNumber(phoneNumber);

    if (isValidPhoneNumber === true) {
      return "";
    } else if (isValidPhoneNumber === false) {
      // Set the lead form status to "warning" via Formik
      setStatus("warning");

      postEvent("error", {
        error: {
          error_message: `Validation for phone number '${phoneNumber}' returned '${isValidPhoneNumber}'.`,
          error_object: "Lead Form",
          error_type: "twilio_validation",
          path: window.location.href,
          invalid_phone_number: phoneNumber,
        },
      });

      errorMessage =
        "The entered phone number appears to be invalid.  Please verify it is correct.";
    } else {
      // Set the lead form status to "error" via Formik
      setStatus("error");

      postEvent("error", {
        error: {
          error_message: `An error occurred while attempting to validate the phone number '${phoneNumber}'.`,
          error_object: "Lead Form",
          error_type: "twilio_validation",
          path: window.location.href,
          invalid_phone_number: phoneNumber,
        },
      });

      errorMessage =
        "An error occurred while validating the phone number. Please try again.";
    }

    // Add an artificial delay to allow loading spinner to display for a moment
    await sleep(600);

    return errorMessage;
  }

  return (
    <>
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Fieldset
        dialog="Your phone number enables direct communication for timely updates and support regarding your debt solutions.  We ask this so that we, our partners and their affiliated companies can contact you about the products and services you inquired about, even if your telephone number is listed on any Do-Not Call list. Contact may be made through automatic dialing systems, artificial or prerecorded voice messaging, or text message."
        dialogHeader="Why do we need your phone number?"
        legend="What is your phone number?"
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Phone Number"
          validate={phoneNumberValidation}
        >
          <Phone id={INPUT_ID_AND_LABEL_HTMLFOR} name={FIELD_NAME_SELECTOR} />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
