"use client";

import { useParams, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import {
  EVENT_JOURNEY_UPDATED,
  EVENT_LANDING_PAGE_VIEWED,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";

import DataLayer from "@client/classes/data-layer/data-layer";
import createVisitCookie from "@client/cookies/createVisitCookie";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

type Props = {
  visitId?: string;
};

export default function VisitCookie({ visitId }: Props) {
  const params = useParams<{ journey?: string }>();
  const searchParams = new URLSearchParams(useSearchParams().toString());
  searchParams.delete("redirect"); // remove "redirect" query param to prevent unintentionally creating new visitId

  // Runs on `load` and when `params`, `searchParams`, or `visitId` changes
  useEffect(() => {
    let visitCookie = getVisitCookie();

    if (
      !visitCookie || // IF `visitCookie` doesn't exist
      (params.journey && // OR `journey` is set
        params.journey !== visitCookie.journey) || // AND the current `journey` doesn't match our saved `journey`
      (searchParams.size > 0 && // OR the current URL contains a query string
        searchParams.toString() !== visitCookie.queryString) // AND query strings don't match
    ) {
      //Log to datadog with all logic checks that trigger createVisitCookie
      let debugData = {
        message: "New visit cookie created",
        event: {
          name: "VisitCookie",
          meta: {
            visitCookiePresent: visitCookie ? true : false,
            journeySet:
              params.journey &&
              params.journey !== (visitCookie && visitCookie.journey),
            journeyMatchesVisitCookie:
              params.journey === (visitCookie && visitCookie.journey),
            currentUrlContainsQueryString: searchParams.size > 0,
            queryStringMatchesVisitCookie:
              searchParams.toString() ===
              (visitCookie && visitCookie.queryString),
            paramsJourney: params.journey,
            visitCookieJourney: visitCookie?.journey,
            searchParams: searchParams.toString(),
            visitCookieQueryString: visitCookie?.queryString,
          },
        },
        status: "info",
      };
      logDebug(debugData);

      createVisitCookie({
        cookiebotEnabled: visitCookie?.cookiebotEnabled,
        geoLocation: visitCookie?.geoLocation || ({} as Visit.Geolocation),
        journey: params.journey || "", // don't set a journey if you're not in a journey.
        landingPage: window.location.pathname,
        neverbounceEnabled: visitCookie?.neverbounceEnabled,
        queryString: searchParams.toString(),
        twilioEnabled: visitCookie?.twilioEnabled,
        visitId: visitCookie?.journey === "" ? String(visitId) : uuid(), // if setting journey for first time, don't change visitId
      });

      // queue `EVENT_LANDING_PAGE_VIEWED` every time the `visit` cookie is updated
      DataLayer.events.queue(EVENT_LANDING_PAGE_VIEWED);

      // IF the `journey` has changed, queue `EVENT_JOURNEY_UPDATED`
      params.journey &&
        params.journey !== visitCookie?.journey &&
        DataLayer.events.queue(EVENT_JOURNEY_UPDATED);
    }
  }, [params, searchParams, visitId]);

  return null;
}
